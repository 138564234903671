import React from 'react'

import LoadingPane from '../../components/Shared/LoadingPane'

const Login = (props) => {
    const { handleChange, login, state } = props
    const { email, loading, password } = state

    return (
        <div className='login-view full-screen-view'>
            {loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}

            <img 
                alt='lw-logo'
                className='lw-logo'
                src={require('../../theme/assets/lw-logo-2x.png')}
            />

            <div className='form-frame'>
                <div className='form-input-frame'>
                    <div className='form-header'>Sign into Provider Portal</div>

                    <input
                        className='form-input'
                        onChange={e => handleChange('email', e.target.value)}
                        placeholder='Email'
                        value={email}
                    />

                    <input
                        className='form-input'
                        onChange={e => handleChange('password', e.target.value)}
                        placeholder='Password'
                        type='password'
                        value={password}
                    />

                    <button className='sign-in-button' onClick={login}>Sign In</button>
                </div>

                <div className='toolbar-frame'>
                    <div className='toolbar-label'>Don't have an account?</div>
                    
                    {/* <button className='request-one-button'>Request One</button> */}

                    <a
                        className='request-one-button'
                        href='https://msprecovery.lifewallet.com/signup' 
                        target='_blank'
                        rel='noreferrer'
                    >Request One</a>
                </div>
            </div>
        </div>
    )
}

export default Login