import { gql } from '@apollo/client'

const HealthSnapUserQuery = gql`
    query HealthSnapUserQuery($user: ID, $healthSnap: [ID!]!) {
        viewing(user: $user) {
            user {
                id
                healthSnaps(ids: $healthSnap) {
                    nodes {
                        id
                        assessment {
                            id
                            answersFromUser {
                                id
                                answer {
                                    id
                                    key
                                    data
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default HealthSnapUserQuery