import React from 'react'

import _ from 'lodash'
import moment from 'moment'

const FamilyHistory = (props) => {
    const { group, state } = props
    const { answers } = state

    let arr = answers[`person.${group.label}`],
        data = (arr.length > 0) ? JSON.parse(arr) : []

    return (
        <div className='section-frame'>
            <div className='section-document-frame'>
                <div className='section-header'>Family Members</div>

                <div className='section-table-view'>
                    <div className='table-headers'>
                        <div className='table-header name'>Relationship</div>
                        <div className='table-header relationship'>Status</div>
                        <div className='table-header status'>Age</div>
                        <div className='table-header phone'>Medical Issues</div>
                    </div>

                    {data.length > 0 ?
                        <ul className='section-list-view'>
                            {data.map((o, i) => {
                                const { alive, dob, issues, relationship } = o

                                let relationships = {
                                    'grandmother_mom': "Grandmother (Mom's Side)",
                                    'grandmother_dad': "Grandmother (Father's Side)",
                                    'grandfather_mom': "Grandfather (Mom's Side)",
                                    'grandfather_dad': "Grandfather (Father's Side)"
                                }, formattedRelationship = (relationship.includes('_')) ? relationships[relationship] : relationship

                                return (
                                    <li className='section-cell' key={i}>
                                        <div className='cell-label name'>{_.capitalize(formattedRelationship) || '-'}</div>
                                        <div className='cell-label relationship'>{(typeof alive !== 'undefined') ? (Boolean(alive) ? 'Alive' : 'Deceased') : '-'}</div>
                                        <div className='cell-label status'>{dob ? `${moment().diff(moment(dob), 'years')} yrs` : '-'}</div>
                                        <div className='cell-label phone'>{issues ? issues.toString().replace(/,/g, ', ') : '-'}</div>
                                    </li>
                                )
                            })}
                        </ul>
                        :
                        <div style={{ fontSize: 12, color: '#73787B', fontStyle: 'italic', textAlign: 'center', padding: 16.5 }}>There are no items to display.</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FamilyHistory