import React from 'react'

export default class FloatingInput extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isFocus: false,
            value: props.value
        }
    }

    componentDidUpdate() {
        const { value } = this.props

        if (this.state.value !== value) {
            this.handleChange('value', value)
        }
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    render() {
        const { className, label, onChange, style, type } = this.props
        const { isFocus, value } = this.state

        return (
            <div className={`floating-input-view${className ? ` ${className}` : ''}`} style={style}>
                <div className={`floating-label ${isFocus || value ? 'focus' : ''}`}>{label}</div>

                <input
                    className={`floating-input ${isFocus || value ? 'focus' : ''}`}
                    onBlur={() => this.handleChange('isFocus', false)}
                    onChange={onChange}
                    onFocus={() => this.handleChange('isFocus', true)}
                    value={value}
                    type={type}
                />
            </div>
        )
    }
}