import React from 'react'
import { graphql } from '@apollo/client/react/hoc'

import { flowRight as compose } from 'lodash'
import Cookie from 'js-cookie'

import LoginMutation from '../../graphql/mutations/Login'
import UserUpdateMutation from '../../graphql/mutations/User/Update'

import UserQuery from '../../graphql/queries/User'

import GettingStarted from '../../components/GettingStarted'
import LoadingPane from '../../components/Shared/LoadingPane'

class GettingStartedContainer extends React.Component {
    state = {
        consent: false,
        password: '',
        password2: '',
        onload: true
    }

    componentDidMount() {
        this.setup()
    }

    componentDidUpdate() {
        this.setup()
    }
    
    setup = () => {
        const { history, userQuery: { loading, user } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            if (user) {
                let email = user.emails.find(o => o.label === 'main')

                this.setState({
                    ...user,
                    email: email ? email.address : '',
                    onload: false
                })
            } else {
                history.push('/')
            }
        }
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    save = () => {
        const { password, password2 } = this.state

        if (password !== password2) {
            window.alert('Passwords do not match.')
        } else {
            this.setState({ loading: true }, this.login)
        }
    }

    login = () => {
        const { loginMutation } = this.props
        const { email } = this.state

        loginMutation({
            variables: {
                input: {
                    email,
                    password: 'Welcome2022!'
                }
            }
        }).then(response => {
            const { errors, user } = response.data.login

            if (user) {
                this.userUpdate(user)
            } else {
                this.handleChange('loading', false)

                setTimeout(() => window.alert(errors[0].message), 150)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    userUpdate = ({ id, token }) => {
        const { userUpdateMutation } = this.props
        const { password } = this.state

        userUpdateMutation({
            variables: {
                auth: token,
                input: {
                    id,
                    password,
                    preferences: [{ key: 'provider.consentAt', value: new Date().toISOString() }]
                }
            }
        }).then(response => {
            const { errors, user } = response.data.userUpdate

            if (user) {
                let role = user.roles.find(r => r.role === 'provider')

                Cookie.set('msp-provider-portal', token)
                Cookie.set('msp-provider-portal-role', role.orgId)

                window.location.reload()
            } else {
                this.handleChange('loading', false)

                setTimeout(() => window.alert(errors[0].message), 150)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        return this.state.onload ?
            <LoadingPane />
            : 
            <GettingStarted
                handleChange={this.handleChange}
                save={this.save}
                state={this.state}
            />
    }
}

export default compose(
    graphql(UserQuery, {
        name: 'userQuery',
        options: (props) => ({
            variables: {
                id: new URLSearchParams(props.history.location.search).get('user')
            }
        })
    }),
    graphql(LoginMutation, { name: 'loginMutation' }),
    graphql(UserUpdateMutation, { name: 'userUpdateMutation' })
)(GettingStartedContainer)