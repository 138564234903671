import { gql } from '@apollo/client'

const LoginMutation = gql`
    mutation LoginMutation($input: UserLoginInput) {
        login(input: $input) {
            errors {
                code
                message
            }
            user {
                id
                roles {
                    id
                    role
                    orgId
                }
                token
            }
        }
    }
`

export default LoginMutation