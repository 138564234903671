import React from 'react'

import moment from 'moment'

import UserPane from './panes/User'

const Dashboard = (props) => {
    const { goToHealthSnapRoute, handleChange, state, statuses } = props
    const { me, isUser, org, role } = state

    return (
        <div className='dashboard-view full-screen-view'>
            <div className='toolbar'>
                <div className='dashboard-button'>
                    <div className='app-name'>Provider Portal</div>
                </div>

                <div className='user-button' onClick={() => handleChange('isUser', true)}>
                    <div className='user-frame'>
                        <div className='user'>{`${me.firstName} ${me.lastName}`}</div>
                        <div className='org'>{role.org.name}</div>
                    </div>

                    <img
                        alt='caret-icon'
                        className='caret-icon'
                        src={require('../../theme/assets/caret-icon-2x.png')}
                    />
                </div>
            </div>

            <div className='content-frame'>
                <div className='health-snaps-table-view'>
                    <div className='table-headers'>
                        <div className='table-header patient'>Patient</div>
                        <div className='table-header'>Date of Birth</div>
                        <div className='table-header'>Facility</div>
                        <div className='table-header'>Status</div>
                        <div className='table-header'>Date</div>
                    </div>

                    <ul className='healthsnaps-list-view'>
                        {org.healthSnaps.nodes.filter(h => h.assessmentId).map(healthSnap => {
                            const { createdAtIso, id, organization, status, user } = healthSnap

                            let attachment = user.attachments.find(o => o.label === 'profile'),
                                formattedStatus = statuses[status] || { color: '#73787B', text: 'Pending' },
                                profileColor = user.preferences.find(o => o.key === 'profile.color')

                            return (
                                  <li 
                                    className='healthsnap-cell' 
                                    key={id}
                                    onClick={() => goToHealthSnapRoute(id)}
                                >
                                    <div className='patient-frame'>
                                        {attachment ?
                                            <img
                                                alt='profile-pic'
                                                className='profile-picture'
                                                src={attachment.contentUrl}
                                            /> 
                                        :
                                            <div className='initials-frame' style={{ background: profileColor.value[0] }}>
                                                <div className='initials'>{`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}</div>
                                            </div>
                                        }

                                        <div className='cell-label patient'>{`${user.firstName} ${user.lastName}`}</div>
                                    </div>

                                    <div className='cell-label'>{moment(user.dob).format('MM/DD/YYYY')}</div>
                                    <div className='cell-label'>{organization.name}</div>
                                    <div className='cell-label bold' style={{ color: formattedStatus.color }}>{formattedStatus.text}</div>
                                    <div className='cell-label'>{moment(createdAtIso).format('MM/DD/YYYY')}</div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>

            {isUser ? <UserPane {...props}/> : null}
        </div>
    )
}

export default Dashboard