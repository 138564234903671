import { gql } from '@apollo/client'

const UserQuery = gql`
    query UserQuery($id: ID) {
        user(id: $id) {
            id
            firstName
            lastName
            emails {
                id
                label
                address
            }
        }
    }
`

export default UserQuery