import { gql } from '@apollo/client'

const MeQuery = gql`
    query MeQuery {
        me {
            id
        }
    }
`

export default MeQuery