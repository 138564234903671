import { gql } from '@apollo/client'

const HealthSnapQuery = gql`
    query HealthSnapQuery($id: ID, $healthSnap: [ID!]) {
        org(id: $id) {
            id
            healthSnaps(ids: $healthSnap) {
                nodes {
                    id
                    assessment {
                        id
                        answersFromUser {
                            id
                            answer {
                                id
                                key
                                data
                            }
                        }
                        program
                    }
                    dataValues {
                            id
                            key
                            values
                        }
                    name
                    organization {
                        id
                        addresses {
                            id
                            label
                            line1
                            line2
                        }
                        name
                    }
                    status
                    user {
                        id
                        addresses {
                            id
                            label
                            line1
                            line2
                        }
                        dob
                        emails {
                            id
                            label
                            address
                        }
                        firstName
                        lastName
                        latestData(metrics: ["person.gender"]) {
                            id
                            key
                            data
                        }
                        phones {
                            id
                            label
                            number
                        }
                    }
                }
            }
            name
        }
    }
`

export default HealthSnapQuery