import React from 'react'

const LoadingPane = (props) => {
    return (
        <div className='loading-pane' style={props.style}>
            <div className='loader'/>
        </div>
    )
}

export default LoadingPane