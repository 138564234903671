import React from 'react'

const UserPane = (props) => {
    const { handleChange, logout } = props

    return (
        <div className='user-pane'>
            <div className='background' onClick={() => handleChange('isUser', false)}/>

            <div className='user-content-frame'>
                <button className='user-content-button red' onClick={logout}>Logout</button>
            </div>
        </div>
    )
}

export default UserPane