import React from 'react'

const EmergencyContact = (props) => {
    const { group, state } = props
    const { answers } = state

    let arr = answers[`person.${group.label}`],
        data = (arr.length > 0) ? JSON.parse(arr) : []

    return (
        <div className='section-frame'>
            <div className='section-document-frame'>
                <div className='section-header'>Emergency Contacts</div>

                <div className='section-table-view'>
                    <div className='table-headers'>
                        <div className='table-header name'>Name</div>
                        <div className='table-header relationship'>Relationship</div>
                        <div className='table-header address'>Address</div>
                        <div className='table-header phone'>Contact Phone Number</div>
                    </div>

                    {data.length > 0 ?
                        <ul className='section-list-view'>
                            {data.map((o, i) => {
                                const { fullName, phone, relationship } = o

                                let address

                                return (
                                    <li className='section-cell' key={i}>
                                        <div className='cell-label name'>{fullName || '-'}</div>
                                        <div className='cell-label relationship'>{relationship || '-'}</div>
                                        <div className='cell-label address'>{address || '-'}</div>
                                        <div className='cell-label phone'>{phone || '-'}</div>
                                    </li>
                                )
                            })}
                        </ul>
                        :
                        <div style={{ fontSize: 12, color: '#73787B', fontStyle: 'italic', textAlign: 'center', padding: 16.5 }}>There are no emergency contacts to display.</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EmergencyContact