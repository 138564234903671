import React from 'react'

import NotFound from '../../components/NotFound'

export default class NotFoundContainer extends React.Component {
    render() {
        return (
            <NotFound/>
        )
    }
}