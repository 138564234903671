import React from 'react'

import Checkbox from '../Shared/Checkbox'
import FloatingInput from '../Shared/FloatingInput'
import LoadingPane from '../Shared/LoadingPane'

const GettingStarted = (props) => {
    const { handleChange, save, state } = props
    const { consent, email, firstName, lastName, loading, password, password2 } = state

    let valid = (firstName && lastName && email && password && consent)

    return (
        <div className='getting-started-view full-screen-view'>
            {loading ? <LoadingPane style={{ background: 'white' }} /> : null}

            <div className='toolbar'>
                <div className='page-header'>Setup Your Account</div>
            </div>

            <div className='content-frame'>
                <div className='document-frame'>
                    <img
                        alt='banner-icon'
                        className='banner-icon'
                        src={require('../../theme/assets/provider-signup-banner.png')}
                    />

                    <div className='section-frame'>
                        <div className='section-header'>Profile Information</div>

                        <div className='form-frame'>
                            <FloatingInput
                                label='First Name'
                                onChange={e => handleChange('firstName', e.target.value)}
                                value={firstName}
                            />

                            <FloatingInput
                                className='middle'
                                label='Last Name'
                                onChange={e => handleChange('lastName', e.target.value)}
                                value={lastName}
                            />  

                            <FloatingInput
                                label='Email'
                                onChange={e => handleChange('email', e.target.value)}
                                value={email}
                            />

                            <FloatingInput
                                label='Password'
                                onChange={e => handleChange('password', e.target.value)}
                                type='password'
                                value={password}
                            />

                            <FloatingInput
                                className='middle'
                                label='Password Confirmation'
                                onChange={e => handleChange('password2', e.target.value)}
                                type='password'
                                value={password2}
                            />
                        </div>

                        <div className='checkbox-frame'>
                            <Checkbox
                                checked={consent}
                                onChange={checked => handleChange('consent', checked) }
                                readOnly={false}
                            />

                            <div className='checkbox-label'>
                                I agree to the

                                <a
                                    className='provider-link'
                                    href={'https://www.lifewallet.com/LifeWallet/wp-content/uploads/2022/04/Terms-Providers-4.20.22-1.50pm.pdf'}
                                    rel="noreferrer"
                                    target='_blank'
                                >{`Terms & Conditions`}</a>,
                                <a
                                    className='provider-link'
                                    href={'https://www.lifewallet.com/LifeWallet/wp-content/uploads/2022/04/Privacy-Policy-Providers-4.22.2022.pdf'}
                                    rel="noreferrer"
                                    target='_blank'
                                >{`Privacy Policy`}</a>, and

                                <a
                                    className='provider-link'
                                    href={'https://www.lifewallet.com/LifeWallet/wp-content/uploads/2022/04/Clients-BAA-LifeWallet.pdf'}
                                    rel="noreferrer"
                                    target='_blank'
                                >{`Business Associate Agreement`}</a>
                            </div>
                        </div>
                    </div>

                    <div className='button-frame'>
                        <button 
                            className={`complete-account-button ${valid ? '' : 'disabled'}`}
                            disabled={!valid}
                            onClick={save}
                        >Complete Account</button>
                    </div>
                {/* end */}
                </div>
            </div>
        </div>
    )
}

export default GettingStarted