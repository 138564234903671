import React from 'react'
import _ from 'lodash'

const Insurance = (props) => {
    const { state } = props
    const { answers } = state

    let arr = answers['person.insurances'],
        data = (arr.length > 0) ? JSON.parse(arr) : []

    return (
        <ul className='insurances-list-view'>
            {data.map((o, i) => {
                const { carrier, memberNumber, groupNumber, primaryName, status } = o

                return (
                    <div className='section-frame' key={i}>
                        <div className='section-document-frame'>
                            <div className='section-header'>{carrier}</div>

                            <div className='form-frame'>
                                <ul className='questions-list-view'>
                                    <li className='section-question-cell' key={0}>
                                        <div className='section-key'>Insurance Carrier</div>
                                        <div className='section-value'>{carrier || '-'}</div>
                                    </li>

                                    <li className='section-question-cell' key={1}>
                                        <div className='section-key'>Member Number</div>
                                        <div className='section-value'>{memberNumber || '-'}</div>
                                    </li>

                                    <li className='section-question-cell' key={2}>
                                        <div className='section-key'>Group Number</div>
                                        <div className='section-value'>{groupNumber || '-'}</div>
                                    </li>

                                    <li className='section-question-cell' key={3}>
                                        <div className='section-key'>Primary Card Holder</div>
                                        <div className='section-value'>{primaryName || '-'}</div>
                                    </li>

                                    <li className='section-question-cell' key={4}>
                                        <div className='section-key'>Insurance Status</div>
                                        <div className='section-value ' style={{ color: (status === 'active') ? '#3BC86B' : '#FB3156' }}>{_.capitalize(status) || '-'}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            })}
        </ul>
    )
}

export default Insurance