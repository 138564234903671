import React from 'react'

import _ from 'lodash'
import moment from 'moment'

const SocialHistory = (props) => {
    const { group, state } = props
    const { answers } = state

    let arr = JSON.parse(answers['person.social_history']),
        data = (arr.length > 0) ? arr : [{ type: 'alcohol', status: undefined }, { type: 'drugs', status: undefined }, { type: 'exercise', status: undefined }, { type: 'tobacco', status: undefined }]

    return (
        <ul className='groups-list-view'>
            {data.map((group, i) => {
                const { freq, status, type } = group

                return (
                    <div className='section-frame' key={i}>
                        <div className='section-document-frame'>
                            <div className='section-header'>{_.capitalize(type)}</div>

                            <div className='section-table-view'>
                                <div className='table-headers'>
                                    <div className='table-header title long'>Status</div>
                                    <div className='table-header start-date'>Frequency</div>
                                </div>

                                <ul className='section-list-view'>
                                    <li className='section-cell' key={i}>
                                        <div className='cell-label title long bold'>{(typeof status !== 'undefined') ? (Boolean(status) ? 'Yes' : 'No') : '-'}</div>
                                        <div className='cell-label start-date'>{_.capitalize(freq) || '-'}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            })}
        </ul>
    )
}

export default SocialHistory