import React from 'react'
import { graphql } from '@apollo/client/react/hoc'

import { flowRight as compose } from 'lodash'
import Cookie from 'js-cookie'

import DashboardQuery from '../../graphql/queries/Dashboard'

import Dashboard from '../../components/Dashboard'

import LoadingPane from '../../components/Shared/LoadingPane'

const statuses = {
    closed: {
        color: '#3BC86B',
        text: 'Complete'
    },
    open: {
        color: '#73787B',
        text: 'Pending'
    }
}

class DashboardContainer extends React.Component {
    state = {
        onload: true
    }

    componentDidMount() {
        this.setup()
    }

    componentDidUpdate() {
        this.setup()
    }

    goToHealthSnapRoute = (id) => {
        this.props.history.push(`/healthsnaps/${id}`)
    }

    setup = () => {
        const { loading, me, org } = this.props.dashboardQuery
        const { onload } = this.state

        if (onload && !loading) {
            this.setState({
                me,
                onload: false,
                org,
                role: me.roles.find(r => (r.role === 'provider') && (r.org.id === Cookie.get('msp-provider-portal-role')))
            })
        }
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    logout = () => {
        Cookie.remove('msp-provider-portal')

        window.location.reload()
    }

    render() {
        return this.state.onload ?
            <LoadingPane />
            :
            <Dashboard
                goToHealthSnapRoute={this.goToHealthSnapRoute}
                handleChange={this.handleChange}
                logout={this.logout}
                state={this.state}
                statuses={statuses}
            />
    }
}

export default compose(
    graphql(DashboardQuery, { 
        name: 'dashboardQuery',
        options: (props) => ({
            variables: {
                id: Cookie.get('msp-provider-portal-role')
            }
        })
    })
)(DashboardContainer)