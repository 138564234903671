import React from 'react'

import _ from 'lodash'
import moment from 'moment'

const HealthHistory = (props) => {
    const { group, state } = props
    const { answers } = state

    let allergies = answers['person.allergies'],
        issues = answers['person.issues'],
        medications = answers['person.medications'],
        groups = [
            { header: 'Medications', data: allergies ? JSON.parse(allergies) : [] },
            { header: 'Medical Issues', data: allergies ? JSON.parse(issues) : [] },
            { header: 'Allergies', data: allergies ? JSON.parse(medications) : [] }
        ]

    return (
        <ul className='groups-list-view'>
            {groups.map((group, i) => {
                const { data, header } = group

                return (
                    <div className='section-frame' key={i}>
                        <div className='section-document-frame'>
                            <div className='section-header'>{header}</div>

                            <div className='section-table-view'>
                                <div className='table-headers'>
                                    <div className='table-header title'>Title</div>
                                    <div className='table-header start-date'>Start Date</div>
                                    <div className='table-header status'>Status</div>
                                </div>

                                {data.length > 0 ?
                                    <ul className='section-list-view'>
                                        {data.map((o, i) => {
                                            const { date, name, status } = o

                                            return (
                                                <li className='section-cell'>
                                                    <div className='cell-label title bold'>{name || '-'}</div>
                                                    <div className='cell-label start-date'>{moment(date).format('MMM DD, YYYY') || '-'}</div>
                                                    <div className='cell-label status bold' style={{ color: (status === 'active') ? '#3BC86B' : '#FB3156' }}>{_.capitalize(status) || '-'}</div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    :
                                    <div style={{ fontSize: 12, color: '#73787B', fontStyle: 'italic', textAlign: 'center', padding: 16.5 }}>There are no items to display.</div>
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </ul>
    )
}

export default HealthHistory