import React from 'react'

import moment from 'moment'
import _ from 'lodash'

import LoadingPane from '../../Shared/LoadingPane'

import EmergencyContact from './EmergencyContact'
import Insurance from './Insurance'
import FamilyHistory from './FamilyHistory'
import HealthHistory from './HealthHistory'
import SocialHistory from './SocialHistory'

const statuses = {
    closed: {
        color: '#3BC86B',
        text: 'Complete'
    },
    open: {
        color: '#73787B',
        text: 'Pending'
    }
}

const HealthSnap = (props) => {
    const { goToHealthSnapRoute, match, state } = props
    const { answers, loading, qHash } = state

    if (loading) {
        return <LoadingPane />
    } else {
        let section = qHash[match.params.section],
            sectionName = section.conditions[0].value

        return (
            <div className='healthsnap-section-view full-screen-view'>
                <div className='toolbar'>
                    <button className='cancel-button' onClick={goToHealthSnapRoute}>Cancel</button>

                    <div className='page-header-frame'>
                        <div className='section-name'>{sectionName}</div>

                        <div className='section-detail'>
                            <div className='section-label'>{`Section ${parseInt(match.params.section) + 1} of ${Object.keys(qHash).length} -`}</div>
                            <div className='status bold' style={{ color: statuses['closed'].color }}>{statuses['closed'].text}</div>
                        </div>
                    </div>

                    <button className='print-button'>Print Packet</button>
                </div>

                <div className='content-frame'>
                    {/* only show default question values when they are present */}
                    {section.questions.length > 0 ?
                        <div className='section-frame'>
                            <div className='section-document-frame'>
                                <div className='section-header'>{sectionName}</div>

                                <div className='form-frame'>
                                    <ul className='questions-list-view'>
                                        {section.questions.map(question => {
                                            const { key } = question

                                            let formattedKey = key.split('.')[1].replace(/_/g, ' '),
                                                formattedAddrKey = key.split('.')[3],
                                                answer = answers[key]

                                            return (
                                                <li className='section-question-cell' key={key}>
                                                    <div className='section-key'>{(formattedKey === 'address') ? formattedAddrKey : formattedKey}</div>
                                                    <div className={`section-value ${answer ? '' : 'hint'}`}>{answer ? (question.kind === 'date') ? moment(answer).format('MMM DD, YYYY') : answer : '-'}</div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                    <ul className='groups-list-view'>
                        {section.groups.map(group => {
                            if (group.label === 'emergency_contact') return <EmergencyContact {...props} group={group} />
                            else if (group.label === 'insurance_info') return <Insurance {...props} group={group} />
                            else if (group.label === 'family_history') return <FamilyHistory {...props} group={group} /> 
                            else if (group.label === 'health_history') return <HealthHistory {...props} group={group} /> 
                            else if (group.label === 'social_history') return <SocialHistory {...props} group={group} /> 
                            else {
                                return (
                                    <div>{`Section not found: ${group.label}`}</div>
                                )
                            }
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

export default HealthSnap