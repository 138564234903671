import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { graphql } from '@apollo/client/react/hoc'

import { flowRight as compose } from 'lodash'

import MeQuery from '../graphql/queries/Me'

import Dashboard from './Dashboard'
import GettingStarted from './GettingStarted'
import HealthSnap from './HealthSnap'
import HealthSnapSection from './HealthSnap/Section'
import HealthSnapPDF from './HealthSnap/PDF'
import Login from './Login'
import NotFound from './NotFound'

import LoadingPane from '../components/Shared/LoadingPane'

class Main extends React.Component {
    state = {
        onload: true
    }

    componentDidMount() {
        this.setup()
    }

    componentDidUpdate() {
        this.setup()
    }

    setup = () => {
        const { loading, me } = this.props.meQuery
        const { onload } = this.state

        let allowed = ['/', '/getting_started'],
            url = window.location.pathname

        if (onload && !loading) {
            if (me && allowed.includes(url)) {
                window.location.href = '/dashboard'
            } else if (me) {
                this.setState({
                    authenticated: me ? true : false,
                    onload: false
                })
            } else if (!me && (!allowed.includes(url))) {
                window.location.href = '/'
            } else {
                this.setState({ onload: false })
            }
        }
    }

    render() {
        const { authenticated, onload } = this.state

        return onload ?
            <LoadingPane />
            :
            authenticated ? 
                <Switch>
                    <Route exact path='/' component={Login} />
                    
                    <Route exact path='/dashboard' component={Dashboard} />
                    <Route exact path='/healthsnaps/:healthSnap' component={HealthSnap} />
                    <Route exact path='/healthsnaps/:healthSnap/sections/:section' component={HealthSnapSection} />
                    <Route exact path='/healthsnaps/:healthSnap/pdf' component={HealthSnapPDF} />
                    
                    <Route component={NotFound} />
                </Switch>
                :
                <Switch>
                    <Route exact path='/getting_started' component={GettingStarted} />
                    <Route path='/' component={Login} />
                </Switch>
    }
}

export default compose(
    graphql(MeQuery, { name: 'meQuery' })
)(Main)