import { gql } from '@apollo/client'

const UserUpdateMutation = gql`
    mutation UserUpdateMutation($auth: String, $input: UserUpdateInput) {
        userUpdate(auth: $auth, input: $input) {
            errors {
                code
                message
            }
            user {
                id
                roles {
                    id
                    role
                    orgId
                }
            }
        }
    }
`

export default UserUpdateMutation