import React from 'react'

import moment from 'moment'

const HealthSnap = (props) => {
    const { goToDashboardRoute, goToHealthSnapPdfRoute, goToHealthSnapSectionRoute, state } = props
    const { healthSnap, sections } = state
    const { name, user } = healthSnap

    let address = user.addresses.find(o => o.label === 'main'),
        email = user.emails.find(o => o.label === 'main'),
        phone = user.phones.find(o => o.label === 'main')
    
    return (
        <div className='healthsnap-view full-screen-view'>
            <div className='toolbar'>
                <button className='cancel-button' onClick={goToDashboardRoute}>Cancel</button>

                <div className='page-header'>{name}</div>

                <button className='print-button' onClick={goToHealthSnapPdfRoute}>Print Packet</button>
            </div>

            <div className='content-frame'>
                <div className='section-frame'>
                    <div className='section-document-frame'>
                        <div className='section-header'>{`${user.firstName} ${user.lastName}`}</div>

                        <div className='user-details-frame'>
                            <div className='user-detail-frame first'>
                                <div className='user-label'>{`Female | ${moment().diff(user.dob, 'years')} yrs old`}</div>
                                <div className='user-label'>{`D.O.B. ${moment(user.dob).format('MM/DD/YYYY')}`}</div>
                            </div>

                            <div className='user-detail-frame'>
                                <div className={`user-label ${address ? '' : 'hint'}`}>{address ? address.line1 : 'No address provided'}</div>
                                <div className='user-label'>{address ? address.line2 : ''}</div>
                            </div>

                            <div className='user-detail-frame'>
                                <div className={`user-label ${phone ? '' : 'hint'}`}>{phone ? phone.number : 'No phone provided'}</div>
                                <div className={`user-label ${email ? '' : 'hint'}`}>{email ? email.address : 'No email provided'}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='section-frame'>
                    <div className='section-document-frame'>
                        <div className='section-subheader'>Form Sections</div>

                        <div className='sections-table-view'>
                            <div className='table-headers'>
                                <div className='table-header name'>Name</div>
                                <div className='table-header'>Status</div>
                            </div>

                            <ul className='sections-list-view'>
                                {sections.map((section, i) => {
                                    let name = section.conditions[0].value

                                    return (
                                        <li className='section-cell' key={i}>
                                            <div className='cell-label name'>{name}</div>

                                            <div className='status-frame'>
                                                <div className='cell-label'>Complete</div>

                                                <button className='view-results-button' onClick={() => goToHealthSnapSectionRoute(i)}>View Results</button>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HealthSnap