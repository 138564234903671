import React from 'react'

const NotFound = (props) => {
    return (
        <div className='not-found-view full-screen-view'>
            <img
                alt='lw-logo'
                className='lw-logo'
                src={require('../../theme/assets/lw-logo-2x.png')}
            />

            <div className='not-found-label'>This page is not found or invalid.</div>
        </div>
    )
}

export default NotFound