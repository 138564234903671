import React from 'react'

const Checkbox = (props) => {
    const { checked, className, onChange, readOnly, type } = props

    if (type === 'radio') {
        return (
            <div className={`radio-checkbox ${className ? className : ''} ${checked ? 'checked' : ''}`}></div>
        )
    } else {
        return (
            <div className={className ? `${className} checkbox` : 'checkbox'}>
                <input
                    type='checkbox'
                    checked={checked}
                    onChange={e => {
                        if (!readOnly) onChange(e.target.checked)
                    }}
                    readOnly
                />

                <span className='checkmark' />
            </div>
        )
    }
}

export default Checkbox